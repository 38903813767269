import React from 'react';
import Carousel from 'nuka-carousel';
import styles from "../scss/modules/testimonials.module.scss";
import {graphql, StaticQuery} from "gatsby";

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      autoplay: true,
      pauseOnHover: true,
      wrapAround: false,
      heightMode: "max",
      transitionMode: "fade",
      renderCenterLeftControls: null,
      renderCenterRightControls: null
    };
  }

  render() {
    return (
      <div className={styles.testimonial}>
        <h2>What Our Clients Say</h2>
        <Carousel className={styles.testimonial__inner}
                  slideIndex={this.state.slideIndex}
                  afterSlide={slideIndex => this.setState({ slideIndex })}
                  wrapAround={this.state.wrapAround}
                  pauseOnHover={this.state.pauseOnHover}
                  autoplay={this.state.autoplay}
                  heightMode={this.state.heightMode}
                  transitionMode={this.state.transitionMode}
                  renderCenterLeftControls={this.state.renderCenterLeftControls}
                  renderCenterRightControls={this.state.renderCenterRightControls}
                  currentSlide={this.state.currentSlide}
                  renderBottomCenterControls={() => (
                      <>
                        { Array.from({length:this.props.testimonialData.length}, (value, index) =>
                        <button
                          className={`${styles.testimonial__dots} ${this.state.slideIndex === index ? ` ${styles.testimonial__dots_active}` : ''}`}
                          aria-label={`Testimonial Slide No. ${index + 1}`}
                          key={index}
                          onClick={() => this.setState({slideIndex: index})}>
                        </button>) }
                      </>
                  )}
                  >

          { this.props.testimonialData.map(function(testimonial, index) {
            let testimonialContent = testimonial.node.frontmatter;
            return (
              <div itemScope itemType="https://schema.org/Review" key={index}>
                <blockquote>
                  <p>{testimonialContent.testimonial}</p>
                  <footer itemProp="author" itemScope itemType="https://schema.org/Person">
                    <cite itemProp="name">{testimonialContent.name}</cite>
                  </footer>
                </blockquote>
              </div>)
          }) }
        </Carousel>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: {
              regex: "/\\/testimonials\\/[a-z0-9\\\\-]+.md$/"
            }
          }, 
          sort: {
            fields: frontmatter___rank,
            order: DESC
          },
          limit: 4) {
          edges {
            node {
              id
              frontmatter {
                name
                testimonial
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Testimonials testimonialData={data.allMarkdownRemark.edges} />
    )}
  />
)
