import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/hero-banner"
import Offering from "../components/what-we-offer"
import EventsCta from "../components/cta/cta_events"
import ClientLogos from "../components/client-logos"
import cta from "../scss/elements/buttons.module.scss"
import heading from "../scss/elements/headings.module.scss";
import Testimonials from "../components/testimonials";

// Latest Projects
import LatestProjects from "../components/latest-projects"
import latestProjectStyles from "../scss/modules/latest_projects.module.scss"

// Latest Posts
import LatestPosts from "../components/latest-posts"
import latestPostStyles from "../scss/modules/latest_posts.module.scss"

export default ({ data }) => {
  const projects = data.project;
  const blogs = data.blog;
  const bannerImageSources = [
    data.largeHero.childImageSharp.fluid,
    {
      ...data.smallHero.childImageSharp.fluid,
      media: `(max-width: 800px)`,
    },
  ]


  return (
    <Layout>
      <SEO
        title="Planning & Design Consultancy - Yorkshire"
      />
      <Banner imageSources={bannerImageSources}/>
      <div className={heading.heading__home_intro}>
        <h2>Our aim is to deliver the specialist planning expertise and support necessary to help you achieve your development objectives</h2>
      </div>
      <Offering />
      <div className={latestProjectStyles.latest_projects}>
        <div className={latestProjectStyles.latest_projects__inner}>
            <div className={latestProjectStyles.latest_projects__content}>
                <h2>Our Latest Projects</h2>
                <p>Whatever the nature of your development proposal / planning issue or the stage in the process you are at, we are here to help. Our advice is focussed, affordable and aimed at adding value to your plans.</p>
            </div>
            <div className={latestProjectStyles.latest_projects__items}>
              {
                projects.nodes.map(function(latestProjects, index) {
                  const sources = [
                    latestProjects.frontmatter.featuredImage.childImageSharp.fluid,
                    {
                      ...latestProjects.frontmatter.featuredImage.childImageSharp.fluid,
                      media: `(max-height: 350px)`,
                    },
                  ]
                    return <LatestProjects key={ index }
                            name={ latestProjects.frontmatter.title }
                            image={ latestProjects.frontmatter.featuredImage }
                            imageSources={ sources }
                            url={ latestProjects.fields.slug }/>;
                })
              }
            </div>
            <div className={latestProjectStyles.latest_projects__button}>
                <a className={cta.button} href="/projects">View All Projects</a>
            </div>
        </div>
      </div>
      <EventsCta />

      <div className={latestPostStyles.latest_posts}>
        <div className={latestPostStyles.latest_posts__inner}>
            <div className={latestPostStyles.latest_posts__content}>
                <h2>Latest News</h2>
            </div>
            <div className={latestPostStyles.latest_posts__items}>
              {
                blogs.nodes.map(function(latestPosts, index) {
                    return <LatestPosts key={ index }
                            title={ latestPosts.frontmatter.title }
                            date={ latestPosts.frontmatter.publish_date }
                            avatar={ latestPosts.frontmatter.avatar }
                            author={ latestPosts.frontmatter.author }
                            excerpt={ latestPosts.frontmatter.excerpt }
                            url={latestPosts.fields.slug} />;
                })
              }
            </div>
            <div className={latestPostStyles.latest_posts__button}>
                <a className={cta.button} href="/news">View All News</a>
            </div>
        </div>
      </div>
      <Testimonials />
      <ClientLogos />
    </Layout>
  )
}


export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
      fields {
        slug
      }
    }
    largeHero:file(relativePath: {eq: "Homepage_Urban_Street@2x.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smallHero:file(relativePath: {eq: "Homepage_Urban_Street.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    project:allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/projects\\/[a-z0-9\\\\-]+.md$/"}
      }
      limit: 2
      sort: {
        fields: frontmatter___rank,
        order: ASC
      }) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    testimonials:allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/testimonials\\/[a-z0-9\\\\-]+.md$/"}
      }
      sort: {
        fields: frontmatter___rank,
        order: ASC
      }) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          name
          testimonial
        }
      }
    }
    blog:allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/news\\/[a-z0-9\\\\-]+.md$/"}
      }
      limit: 3
      sort: {
        fields: frontmatter___publish_date,
        order: DESC
      }) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          title
          publish_date
          author
          excerpt
          avatar {
            childImageSharp {
              fluid(maxWidth: 80) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
