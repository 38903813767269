import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import styles from "../scss/modules/client_logos.module.scss";


export default () => {

    const data = useStaticQuery(graphql`
        query {
            bowland:file(relativePath: {eq: "client-logos/clientLogo-bowland.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            woodlandTrust:file(relativePath: {eq: "client-logos/clientLogo-woodland-trust.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            townscape:file(relativePath: {eq: "client-logos/clientLogo-townscape.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            topia:file(relativePath: {eq: "client-logos/clientLogo-topia.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            oconnor:file(relativePath: {eq: "client-logos/clientLogo-oconnor.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            landmarkTrust:file(relativePath: {eq: "client-logos/clientLogo-landmark-trust.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            montpellier:file(relativePath: {eq: "client-logos/clientLogo-montpellier.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            nlgep:file(relativePath: {eq: "client-logos/clientLogo-NLGEP.jpg"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            GWEBiogas:file(relativePath: {eq: "client-logos/clientLogo-GWEBiogas.jpg"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            GBBIO:file(relativePath: {eq: "client-logos/clientLogo-GBBIO.jpg"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            solar21:file(relativePath: {eq: "client-logos/clientLogo-solar-21.png"}) {
                childImageSharp {
                    fixed(width: 125) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.client_logos}>
            <div className={styles.client_logos__inner}>
                <div className={styles.client_logos__content}>
                    <h2>Our Clients & Partners</h2>
                    <p>People we've worked with</p>
                </div>
                <div className={styles.client_logos__images}>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.townscape.childImageSharp.fixed } alt="Townscape Architects" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.landmarkTrust.childImageSharp.fixed } alt="The Landmark Trust" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.bowland.childImageSharp.fixed } alt="Bowland Ecology" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.oconnor.childImageSharp.fixed } alt="Oconnor Chiropractic" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.topia.childImageSharp.fixed } alt="Topia" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.woodlandTrust.childImageSharp.fixed } alt="Woodland Trust" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.montpellier.childImageSharp.fixed } alt="Montpellier Property Consultants" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.nlgep.childImageSharp.fixed } alt="North Lincolnshire Green Energy Park" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.GWEBiogas.childImageSharp.fixed } alt="GWE Biogas" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.GBBIO.childImageSharp.fixed } alt="GB Bio" />
                    </picture>
                    <picture className={styles.client_logos__logo}>
                        <Image fixed={ data.solar21.childImageSharp.fixed } alt="Solar 21" />
                    </picture>
                </div>
            </div>
    </div>
    )
}
