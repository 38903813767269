import React from "react"
import styles from "../scss/modules/latest_projects.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Arrow from "../images/svg/arrow-right.inline.svg"

export default ( props ) => {
  return (
        <div className={styles.latest_projects__item} itemScope itemType="http://schema.org/Project">
            
            <div className={styles.latest_projects__image}>
                <Link to={props.url}>
                <Img 
                    fluid={ props.imageSources } 
                    alt={props.name}
                />
                </Link>
            </div>
            
            <div className={styles.latest_projects__link}>
                <Link to={props.url}>
                        <span itemProp="name">{props.name}</span>
                        <div className={styles.cta}><Arrow /></div>
                </Link>
                </div>
        </div>
  )
}