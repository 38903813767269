import React from "react";
import styles from "../scss/modules/meet_team.module.scss";
import cta from "../scss/elements/buttons.module.scss";
import image from "../images/What_we_Offer@2x.jpg";
import { Link } from "gatsby";


export default ({ data }) => (
    <div className={styles.meet_team}>
        <div className={styles.meet_team__inner}>
            <div className={styles.meet_team__content}>
                <div className={styles.meet_team__content_wrapper}>
                    <h2>What we offer</h2>
                    <p>Our experienced team provide professional planning, drawing and landscape architecture services across residential, commercial, energy and industrial sectors.</p>
                    <p>Working with a range of clients from private individuals and community groups, to landowners, developers and industry leaders, our aim is to deliver the specialist planning expertise and support necessary to help achieve your development objectives.</p>
                    <Link to="/about-us" className={cta.button}>About Us</Link>
                </div>
            </div>
            <div className={styles.meet_team__image}>
                <img src={image} alt={"Alt Tag Goes Here"}></img>
            </div>

        </div>
    </div>
  )
