import React from "react"
import styles from "../scss/modules/latest_posts.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default ( props ) => {
  return (
        <div className={styles.latest_posts__item} itemScope itemType="http://schema.org/Article">
            <div className={styles.latest_posts__content}>
                <span className={styles.date} itemProp="dateCreated">{props.date}</span>
                <h3 className={styles.title} itemProp="name"><Link to={props.url} itemProp="url">{props.title}</Link></h3>
                <p className={styles.excerpt}>{props.excerpt}</p>
                <div className={styles.latest_posts__bio}>
                    <Img className={styles.latest_posts__avatar}
                         fluid={ props.avatar.childImageSharp.fluid } 
                         alt={"By "+props.author}
                    />
                    <span itemProp="author">{"By "+props.author}</span>
                </div>
            </div>
        </div>
  )
}