import React from "react";
import styles from "../../scss/modules/cta_wide.module.scss";
import cta from "../../scss/elements/buttons.module.scss";
import image from "../../images/get_in_touch.jpg";
import { Link } from "gatsby"

export default ({ data }) => (
    <div className={styles.cta_wide}>
        <div className={styles.cta_wide__inner}>
            <div className={styles.cta_wide__content}>
                <h2>Get in touch to see how we can help you.</h2>
                <Link className={cta.button} to="/contact">Find out more</Link>
            </div>
            <div className={styles.cta_wide__image}>
                <img src={image} alt={"Latest Events"}></img>
            </div>
        </div>
    </div>
  )
