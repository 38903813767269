import React from "react";
import styles from "../scss/modules/hero_banner.module.scss";
import Image from "gatsby-image";
import Arrow from "../images/svg/arrow-right.inline.svg"
import { Link } from "gatsby"

export default (props) => {
    return (
    <div className={styles.hero_banner}>
        <div className={styles.hero_banner__inner}>
            <Link to="/about-us">
            <div className={styles.hero_banner__content}>
                <h1 id="title">We are Northern Planners, a Yorkshire-based planning and design consultancy based in North Yorkshire.</h1>
                <div className={styles.cta}><Arrow /></div>
            </div>
            </Link>
            <div className={styles.hero_banner__image} alt={"Northern Planners | Yorkshire based design and consultancy"}>
                <Image fluid={props.imageSources} />
            </div>
        </div>
    </div>
  )
}
  